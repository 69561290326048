import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError, interval, of } from 'rxjs';
import { catchError, retryWhen, flatMap } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {
  constructor(private router: Router) { }

  /**
   * intercept http requests for handle error.
   * If  error is 401 refresh token and Rettry one more time, otherwise I handle the error with catcherror
   * If he returns 401 a second time, throwError
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(catchError(err => this.handleError(request, err)));
  }

  /**
   * Handle Request Error
   */
  public handleError(request: HttpRequest<any>, err) {

    if (err.status === 401) {
      return this.goToLogin();
    } else {
      return throwError(err);
    }
  }

  goToLogin(): Observable<any> {
    return of(this.router.navigate(['/login']));
  }

  /**
   * Retries an HTTP operation for maxRetry number of times after having waited delayMs number of milliseconds.
   * @param maxRetry - The number of retries before failing.
   * @param delayMs - The number of milliseconds to wait before retrying.
   */
  private httpRetry<V>(request: HttpRequest<any>, maxRetry: number = 1, delayMs: number = 5000) {
    return (src: Observable<V>) => src.pipe(
      retryWhen((error) => {
        return error.pipe(
          flatMap(err => {
            return interval(delayMs).pipe(flatMap((count) => {
              if (err.status !== 401 || (count >= maxRetry && err.status === 401)) {
                throw (err);
              } else {
                return of(-this.goToLogin());
              }
            })
            );
          })
        );
      })
    );
  }
}
