import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  private copyrightYear: number;

  constructor() { }

  ngOnInit() {

    this.copyrightYear = (new Date()).getFullYear();
  }

}
