import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { LocalStorageHelper } from '../helpers/local-storage';

@Injectable({
  providedIn: 'root'
})
export class AbstractDataProviderService {

  constructor() { }

  /**
   * Builds a set of HTTP headers to send whenever a GET or DELETE request is issued.
   */
  protected buildGetHeaders(): HttpHeaders {
    let getHeaders = new HttpHeaders();
    const user =  LocalStorageHelper.getUserInfo();
    getHeaders = getHeaders.set('Authorization', 'Bearer ' + user.token);
    return getHeaders;
  }


  /**
   * Builds a set of HTTP headers to send whenever a POST, PUT or PATCH request is issued.
   */
  protected buildPostHeaders(): HttpHeaders {
    const user =  LocalStorageHelper.getUserInfo();
    let postHeaders = new HttpHeaders();
    postHeaders = postHeaders.set('Authorization', 'Bearer ' + user.token);
    postHeaders = postHeaders.set('Content-Type', 'application/json');
    return postHeaders;
  }
}
