import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventEmitService {
  public changeEnvironmentEmit = new Subject<boolean>();
  constructor() { }

  public onChangeEnvironment(): Observable<boolean> {
    return this.changeEnvironmentEmit.asObservable();
  }
  public changeEnvironment() {
    return this.changeEnvironmentEmit.next(true);
  }

}

