


// tslint:disable-next-line:no-namespace
export namespace EndpointNames {
    export const Lego = 'Lego';
}

export enum Enviromments {
    Dev = 1,
    Test = 2,
    Staging = 3,
    Production = 4,
}
