
import { Observable, Subject } from 'rxjs';
import { User } from '../model/user';
const USER_DATA_KEY = 'LegoUserData';
const ENVIRONMENT_KEY = 'environmentSelect';
const storageSub = new Subject<boolean>();

export namespace LocalStorageHelper {

    /**
     * Retrieves user authentication data from the local storage.
     */
    export function getAuthToken(): string {
        let token;

        const userData = localStorage.getItem(USER_DATA_KEY);
        if (userData !== null) {
            const parsedData = JSON.parse(userData);
            token = parsedData.token;
        } else {
            token = null;
        }

        return token;
    }



    /**
     * Stores user personal information (such as first and last names) in
     * the local storage.
     *
     * @param firstName The user's first name.
     * @param lastName The user's last name.
     */
    export function setUserInfo(data: User): void {
            const jsonData = JSON.stringify(data);
            localStorage.setItem(USER_DATA_KEY, jsonData);
    }

    /**
     * Retrieves user personal information (such as first and last names)
     * from the local storage.
     */
    export function getUserInfo(): User {
        let userInfo;

        const userData = localStorage.getItem(USER_DATA_KEY);
        if (userData !== null) {
            const parsedData = JSON.parse(userData);
            userInfo = parsedData;
        } else {
            userInfo = null;
        }

        return userInfo;
    }
    export function removeUser() {
        localStorage.removeItem(USER_DATA_KEY);
    }
    export function watchUserInfo(): Observable<any> {
        return storageSub.asObservable();
    }

}
