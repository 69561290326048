import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { AlertComponent } from './alert/alert.component';

// Modules
import { AppRouterModule } from './app-router/app-router.module';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SelectEnvironmentComponent } from './select-environment/select-environment.component';
import { RequestInterceptorService } from './services/request-intercept.service';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    AlertComponent,
    HomeComponent,
    LeftMenuComponent,
    SidenavComponent,
    FooterComponent,
    HeaderComponent,
    LeftMenuComponent,
    SelectEnvironmentComponent
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
