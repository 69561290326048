import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../services/alert.service';
import { AuthenticationService } from '../services/authentication.service';
import { LocalStorageHelper } from '../helpers/local-storage';
import { User } from '../model/user';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})


export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    loginErrorMessage: string;
    loginFailed = false;
    constructor(
        private router: Router,
        private fb: FormBuilder,
        private authenticationService: AuthenticationService,
        private alertService: AlertService) {
    }

    ngOnInit() {
        this.validateLoginForm();
        // reset login status
        // this.authenticationService.logout();
    }

    validateLoginForm(): void {
        this.loginForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    // getter to access form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.loginFailed = false;
        // returns if invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == null) {
                        this.loginFailed = true;
                        this.loginErrorMessage = 'Username or password invalid.';
                        return;
                    }
                    this.initApp(data);
                },
                error => {
                    this.loginFailed = true;
                    this.loginErrorMessage =  'An unexpected error occurred.';
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
    initApp(data: any) {
        LocalStorageHelper.setUserInfo(data);
        this.router.navigate(['/']);
    }
}
