import { Component, OnInit, Input } from '@angular/core';
import { EnvironmentModel } from '../model/environment-model';
import { LocalStorageHelper } from '../helpers/local-storage';
import { AuthenticationService } from '../services/authentication.service';
import { Subject } from 'rxjs';
import { EventEmitService } from '../services/event-emit.service';

@Component({
  selector: 'app-select-environment',
  templateUrl: './select-environment.component.html',
  styleUrls: ['./select-environment.component.css']
})
export class SelectEnvironmentComponent implements OnInit {

  @Input() environmentAccess: EnvironmentModel[];
  @Input() environmentDefault: EnvironmentModel;
 
  constructor(private authenticationService: AuthenticationService,
              private eventEmitService: EventEmitService) { }

  ngOnInit() {
  }

  changeEnvironment(e) {
    this.authenticationService.setActiveConnection(e).subscribe(_ => {
      this.eventEmitService.changeEnvironment();
    });

  }
}
