import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as AppConstants from '../app-constants';
import { User } from '../model/user';
import { of } from 'rxjs';
import { LocalStorageHelper } from '../helpers/local-storage';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AbstractDataProviderService } from './abstract-data-provider.service';


@Injectable({
  providedIn: 'root'
})



export class AuthenticationService  extends AbstractDataProviderService {

    constructor(private http: HttpClient,
                private router: Router) {
        super();
    }

    login(username: string, password: string) {
        return this.http.post(environment.endpoints.Lego + '/security/login',
        { username, password })
            .pipe(map( ( (user: User) => {
                // login successful
                if (user && user.token) {
                    // store user details in local storage
                    LocalStorageHelper.setUserInfo(user);
                }
                return user;
            })));
    }
    logout() {
        // remove user from local storage to log user out
        const user = LocalStorageHelper.getUserInfo();
        return this.http.post(environment.endpoints.Lego + '/security/logout',
        { userName: user.userName, token: user.token },  { headers: this.buildGetHeaders() })
        .subscribe(log => {
            LocalStorageHelper.removeUser();
            this.router.navigate(['/login']);
        });
    }

    setActiveConnection(environmentId: number) {
        return this.http.post(environment.endpoints.Lego + '/security/setActiveConnection', {environmentId} ,
         { headers: this.buildPostHeaders() });
    }
}
