import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorageHelper } from '../helpers/local-storage';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

      const userInfo = LocalStorageHelper.getUserInfo();
      if (userInfo === null || !userInfo.expToken) {
        // logged in so return true
        this.goToLogin();
        return false;
    }
      if (new Date() >  new Date(userInfo.expToken)) {
        this.goToLogin();
        return false;
      }
      return true;

        // not logged in so redirect to login page with the return url
    }
    goToLogin(): void {
        this.router.navigate(['/login']);
      }
}
