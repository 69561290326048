
import * as AppConstants from '../app/app-constants';

export const environment = {
  production: false,
  endpoints: {
    Lego: 'https://legoservice.gridsystems.io/api',
  },
};

