import { Component, OnInit } from '@angular/core';
import { LocalStorageHelper } from '../helpers/local-storage';
import { AuthenticationService } from '../services/authentication.service';
import { EnvironmentModel } from '../model/environment-model';
import { Enviromments } from '../app-constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public userInfo: string;
  public environmentAccess: EnvironmentModel[] = [];
  public environmentDefault: EnvironmentModel;
  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.setUserInfo();
    LocalStorageHelper.watchUserInfo().subscribe(obs => {
      this.setUserInfo();
    });
  }

  setUserInfo() {
    const user = LocalStorageHelper.getUserInfo();
    this.userInfo = user.userName;
    this.populateEnvironment(user.environments);
  }

  populateEnvironment(access: number[]) {
    this.environmentDefault = {value : access[0], name : Enviromments[access[0]]};
    access.forEach(element => {
      if (this.userInfo === 'Support' &&  element === Enviromments.Production) {
        this.environmentDefault = {value : element, name : Enviromments[element]};
      }
      this.environmentAccess.push({value : element, name : Enviromments[element]});
    });
    this.authenticationService.setActiveConnection(this.environmentDefault.value).subscribe();
  }
  logout() {
    this.authenticationService.logout();
  }
}
