import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from '../login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { AuthGuardService } from '../services/auth-guard.service';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent  },
  {
    path: 'app',
    component: HomeComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'Lego'
    },
    children: [
      { path: 'community', loadChildren: 'src/app/community/community.module#CommunityModule' },
      { path: 'emails', loadChildren: 'src/app/emails/emails.module#EmailsModule' },
      { path: 'configuration', loadChildren: 'src/app/configuration/configuration.module#ConfigurationModule' },
      { path: 'errors', loadChildren: 'src/app/errors/errors.module#ErrorsModule' },
      { path: 'user', loadChildren: 'src/app/user/user.module#UserModule' },
      { path: 'role', loadChildren: 'src/app/role/role.module#RoleModule' },
      { path: 'registration', loadChildren: 'src/app/registration/registration.module#RegistrationModule' },
    ]
  },
  { path: '',   redirectTo: '/app', pathMatch: 'full' },
];

export const routing = RouterModule.forRoot(appRoutes);
@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes),
    CommonModule
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})

export class AppRouterModule { }
